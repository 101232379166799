@import "../style.css";

.custom-button {
  display: flex;
  font-family: "Poppins";
  border: none;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  cursor: pointer;
  text-decoration: none;
}

.green {
  background-color: var(--green-100);
  color: var(--white-200) !important;
}

.white {
  background-color: var(--white-200);
  color: var(--green-100);
}

.green:hover {
  background-color: var(--green-50);
  color: var(--white-200);
}

.white:hover {
  background-color: var(--green-100);
  color: var(--white-200);
}

@media only screen and (max-width: 430px) {
  .custom-button {
    display: flex;
    width: 150px;
    height: 40px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }
}
