.globalWarningWrap {
  display: flex;
  padding: 1em;
}

.globalServiceIncludedWarning {
  display: flex;
  align-items: flex-start; /* Alinha o conteúdo ao topo */
  width: 100%;
}

.material-symbols-outlined {
  color: #d9534f;
  font-size: 35px;
  margin: 12px 20px;
}

.markdown-style {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  color: #333;
}

.markdown-style h1,
.markdown-style h2,
.markdown-style h3 {
  margin: 0 0 10px;
  font-weight: bold;
}

.markdown-style p {
  font-weight: 400;
  align-self: flex-start;
  line-height: 26px;
}

.markdown-style ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 5px; /* Espaço entre o título e a lista */
}

.markdown-style ul li {
  margin-bottom: 15px;
  font-size: 16px;
}

.markdown-style strong {
  font-weight: bold;
}
