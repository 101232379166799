.service-navbar-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 299px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding-left: 0px;
  background-color: var(--green-50);
}
.main-nav-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  .items {
    color: var(--white-200);
    margin: 0px;
    width: 150px !important;
    text-align: center;

    a {
      color: var(--white-200);
      text-decoration: none;
    }
  }
}

.service-navbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-logo {
  h1 {
    display: inline-block;
    white-space: nowrap;
    font-size: 48px;
    font-weight: 300;
    line-height: 50px;
    font-stretch: ultra-condensed;
    color: var(--white-200);

    font-family: "Roboto Serif", serif;
    font-style: normal;
    letter-spacing: 4px;
  }
}
a {
  text-decoration: none;
  color: var(--white-200);

  font-size: 16px;
  font-weight: 400;
  margin: 0 10px;
  position: relative;
}

a.active::after {
  content: "";
  display: block;
  width: 30px;
  height: 5px;
  background: url("../../public/images/linemenuwhite.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .service-navbar-mobile {
    width: 100%;
    height: 170px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding-left: 0px;
    background-color: var(--green-50);
  }
  .service-navbar-container {
    margin-top: 50px;
  }
  .mobile-center-logo {
    h1 {
      color: var(--white-200);

      font-size: 32px;
      font-style: normal;
      font-weight: 50 !important;
      margin: 24px 0px 30px 0px;
    }
  }
  .mobile-main-nav-items {
    text-align: center;
    width: 100%;

    .mobile-items {
      display: inline-block;
      margin: 0;
      padding-bottom: 29px;

      a {
        font-size: 14px;
        color: var(--white-200);
        text-decoration: none;
      }
    }

    .mobile-items {
      text-align: center;
      padding: 0;
      width: 45%;
    }
  }
}
