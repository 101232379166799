.bannerWrapper {
  height: 621px;
}
.bannerService {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.bannerMiddlePhoto img {
  height: 708px;
  width: 708px;
  object-fit: cover;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}

@media (max-width: 430px) {
  .bannerWrapper {
    height: auto;
  }

  .bannerMiddlePhoto img {
    height: 275px;
  }
}
