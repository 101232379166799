:root {
  --green-100: #164734;
  --green-50: #446953;

  --black-300: #000000;
  --black-200: #222222;
  --black-100: #282828;

  --grey-500: #717171;
  --grey-300: #dddddd;
  --grey-200: #e1e1e1;
  --grey-100: #f5f5f5;

  --white-200: #ffffff;
  --white-170: #f7f7f7;

  --media-desktop: 1400px;
  --media-tablet: 992px;
  --media-mobile: 768px;

  .material-symbols-outlined {
    font-variation-settings: "FILL" 1, "wght" 350, "GRAD" 0, "opsz" 24;

    color: red;
  }
}
