@import "../style.css";

.container {
  width: 100%;
}

a {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: var(--green-100);
  margin: -3px;
}

.routine-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid var(--green-50);

  thead {
    background-color: var(--green-100);
    color: var(--white-200);
  }
}

.routine-table th,
.routine-table td {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 10px 30px;
}

th {
  padding: auto 0px !important;
}

td {
  border-right: transparent;
  border-left: transparent;
  border-top: 1px solid var(--green-50);
}

.top-morning,
.top-night {
  background-color: var(--green-50);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.routine-section h2 {
  color: var(--white-200);
  text-transform: capitalize !important;
  letter-spacing: 1px;
}

.days-container {
  display: flex;
  justify-content: space-around;
  gap: 5px;
  margin: 20px 0px;
  width: 100%;
}

.how-to-use-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  td {
    color: var(--green-100);
  }
}

.product {
  width: 20%;
}

.how-to-use {
  width: 50%;
}

.where-to-buy {
  width: 20%;
}

.day {
  display: inline-block;
  font-size: 40px;
}

.day.active {
  color: var(--green-100);
}

.routine-section {
  margin: 20px 60px;

  img {
    margin-right: 20px;
  }
}

.routine-section.morning {
  border-radius: 8px;
  border-color: var(--green-100);
}

.routine-section.night {
  background-color: var(--white-200);
  margin-top: -20px;
}

/* Versão Mobile */
@media (max-width: 768px) {
  .product-name {
    font-size: 18px !important;
    text-align: center !important;
  }

  .how-to-use {
    margin-bottom: 170px !important;
  }
  .routine-table {
    display: flex;
    flex-direction: row;
  }

  .routine-section {
    margin: 20px;
  }

  thead {
    /* padding: 0px 30px; */

    tr {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      width: 100%;
      height: 60vh;
      border-bottom: 1px solid var(--green-50);

      th {
        font-weight: bold !important;
        white-space: nowrap; /* Impede a quebra de linha */
        text-align: center;
        padding: 10px 0px;
      }
    }
  }

  tbody {
    tr {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0px 5px;
      height: 60vh;
      width: 99%;
      border-bottom: 1px solid var(--green-50);
    }
  }

  .routine-table th {
    display: inline-block; /* Garante que os elementos fiquem em linha */
    width: auto; /* Ajusta automaticamente a largura */
  }

  .routine-table td {
    border-color: transparent;
    padding: 10px 0px;
    margin: 0px 10px;
  }
}
