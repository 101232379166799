.containerService {
  box-sizing: border-box;
  height: auto;
  overflow: hidden;
}
.mobileNavbarWrapper {
  width: 100vw;
}

.bannerService {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
.bannerMiddle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  z-index: 1;
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 101px;
}

.bannerMiddleContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0px auto;
  width: 100%;
  margin: 0px 0px 32px 101px;

  h2 {
    font-size: 32px;
    letter-spacing: 4px;
    padding-bottom: 35px;
    margin: 0;
    letter-spacing: 1px;
    color: var(--green-100);
  }

  h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 24px;
    padding-top: 35px;
  }

  .title {
    grid-column: 1 / 2;
    margin: 89px 0px 0px 0px;
  }

  .titleDescription {
    grid-column: 1 / 2;
    margin: 162px 128px 0px 0px;
  }

  .bannerButton {
    padding-top: 30px;
    padding-bottom: 43px;
  }
}
.bannerMiddleRecommendation {
  margin-top: 10px;
}
.sessionTitle {
  font-family: "Raleway";
  font-weight: 550;
  font-size: 16px;
  padding-bottom: 10px !important;
}
.bannerMiddleContentDetails h5 {
  font-family: "Raleway";
  font-weight: 550;
  font-size: 16px;
}
.bannerMiddleContentDetails > div {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    padding-bottom: 0px;
  }
}

.bannerMiddlePhoto {
  margin-top: 80px;
}
.bannerMiddlePhoto img {
  height: 630px;
  width: 708px;
  object-fit: cover;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}
.bannerBottom {
  padding-top: 30px;
}

.descriptionStyle p {
  display: inline;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .mobileNavbarWrapper {
    padding-bottom: 50px;
  }
  .bannerWrapper {
    height: auto;
  }
  .bannerMiddle {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    justify-content: flex-start;
    padding-left: 0;

    h2 {
      font-size: 28px;
      letter-spacing: 2px;
      padding-bottom: 40px;
    }
  }

  .bannerMiddleContent {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0px 16px 0px 16px;
    margin: 0;

    .title {
      margin: 0px;
      padding-bottom: 15px;
    }
    h2 {
      letter-spacing: 1px;
      font-size: 27px;
    }
  }

  .titleDescription {
    order: 3 !important;
    grid-column: 1 / 2;
    margin: 10px !important;
  }

  .title {
    order: 1;
    margin: 0;
    padding-bottom: 32px;
    text-align: center;
  }

  .bannerMiddlePhoto {
    order: 2 !important;
    display: flex;
    justify-content: center;
    margin: 0px;
    height: 500px;
    overflow: hidden;
  }

  .bannerMiddlePhoto img {
    display: flex;
    object-fit: cover;
    margin-top: 0;
    border-radius: 6px;
    position: relative;
  }

  .globalFirstBannerBottom {
    margin-top: 20px !important;
  }
}
