@import "../style.css";

.our-gallery,
.default-page {
  width: 98vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .link {
    margin: 80px 0;
    text-decoration: none;
    font-family: "Roboto Serif";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 2fr 1.5fr 2fr;
    gap: 10px;
    width: 80%;
    max-width: 997px;
    max-height: 978px;
    margin-bottom: 80px;
  }
  .gallery img {
    width: 100%;
    height: auto;
    display: block;
  }

  #img1 {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
  #img2 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  #img3 {
    grid-column: 3 / 4;
    grid-row: 1 / 4;
  }
  #img4 {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  #img5 {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
  }
  #img6 {
    grid-column: 3 / 4;
    grid-row: 3 / 3;
  }
}

.default-page {
  .link {
    background-color: var(--green-50);
    height: 299px;
    width: 100%;
    color: var(--white-200);
    margin: 0px 0px 80px 0px;
    font-family: "Roboto Serif";
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 50px; /* 104.167% */
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .our-gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .link {
      margin: 40px 0;
    }

    .gallery {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 2fr 1.5fr 2fr;
      gap: 10px;
      width: 80%;
      max-width: 997px;
      max-height: 978px;
      margin-bottom: 40px;
    }

    .gallery img {
      width: 100%;
      height: auto;
      display: block;
    }

    #img1 {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }

    #img2 {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    #img3 {
      grid-column: 3 / 4;
      grid-row: 1 / 4;
    }

    #img4 {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }

    #img5 {
      grid-column: 2 / 3;
      grid-row: 2 / 4;
    }

    #img6 {
      grid-column: 3 / 4;
      grid-row: 3 / 3;
    }
  }
}
