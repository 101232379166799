body {
  margin: 0;
  padding: 0;
}
.navbar-wrapper {
  width: 100%;
  border-bottom: 10px solid var(--green-100);
}
.navbar-desktop {
  display: flex;
  width: 100vw;
  height: 80px;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1000;
  position: relative;
  margin: 0 auto;
}

.navbar-desktop a {
  text-decoration: none;
  color: var(--green-100);
  font-size: 16px;
  font-weight: 400;
  position: relative;
  white-space: nowrap;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: inherit;
  font: inherit;
}

.navbar-desktop ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  height: 27px;
  flex-shrink: 0;
  width: 800px;
}

.navbar-desktop .items {
  position: relative;
  margin-left: 20px;
  width: 115px;
}
.navbar-desktop .service-toggle {
  cursor: pointer;
}

.navbar-desktop .dropdown {
  display: none;
  position: absolute;
  background: var(--white-200);
  border: 1px solid var(--grey-300);
  width: 200px;
  margin-top: 5px;
}

.navbar-desktop .dropdown.show {
  display: block;
}

.navbar-desktop .submenu {
  display: none;
  position: absolute;
  background: var(--white-200);
  border: 1px solid var(--grey-300);
  width: 200px;
  top: 0;
  left: 100%;
  margin-left: 5px;
}

.navbar-desktop .submenu.show {
  display: block;
}

.navbar-desktop .submenu-item {
  position: relative;
}

.navbar-desktop .submenu-item a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: var(--black-300);
}
.navbar-desktop .submenu-item button {
  display: block;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  color: var(--black-300);
  background: transparent;
  border: none;
  text-align: left;
}

.navbar-desktop .submenu-item button:hover,
.navbar-desktop .submenu-item .submenu-link:hover {
  background-color: var(--green-100);
  color: var(--white-200);
}

.navbar-desktop .dropdown .submenu-item {
  display: block;

  .submenu-link {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: var(--black-300);
  }
  .submenu-link:hover {
    background-color: var(--green-100);
    color: var(--white-200);
  }
  a:hover {
    background-color: var(--green-100);
    color: var(--white-200);
  }
}

.navbar-desktop .center-logo {
  width: 300px;
}

.navbar-desktop .center-logo h3 {
  color: var(--green-100);
  padding: 0;
}

.navbar-desktop img {
  width: 254px;
  height: 21px;
  margin-right: 10px;
}

.navbar-desktop a.active::after {
  content: "";
  display: block;
  width: 30px;
  height: 5px;
  background: url("../../public/images/Linemenu.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.myAccount-buttons {
  width: 385px;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-right: 127px;
}

@media (max-width: 768px) {
  .navbar-desktop .center-logo {
    padding-left: 16px;
    h3 {
      display: inline-block;
      letter-spacing: 2px !important;
      white-space: nowrap;
      font-size: 16px;
    }
  }

  .navbar-mobile {
    background: var(--white-200);
    z-index: 1000;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    top: 0;
    border-bottom: 10px solid var(--green-100);
    position: fixed;

    h3 {
      font-family: "SVN-Aire Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      /* 250% */
      letter-spacing: 3.2px;
      text-transform: uppercase;
      margin-left: 16px;
    }

    .hamburger-icon {
      cursor: pointer;
      margin-right: 24px;
    }
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    overflow: auto;
    padding-top: 42px;
  }
  .modal-content {
    background: var(--white-200);
    width: 170px;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .modal-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .modal-content li {
    margin: 10px 0;
  }

  .modal-content li a {
    color: var(--black-300);
    text-decoration: none;
    color: var(--green-100);
    font-size: 18px;
    display: block;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
  }

  .modal-content li:hover {
    background-color: var(--green-100);
  }

  .modal-content li:hover a {
    color: var(--white-200);
  }

  .modal-content li a:hover {
    color: var(--white-200);
  }

  @keyframes slideIn {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .modal-content {
    animation: slideIn 0.5s ease-out;
  }
}
