@import "../style.css";

.myAccount-container {
  background-image: url("../../public/images/flower.svg");
  background-size: repeat;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .green-box {
    width: 580px;
    height: auto;
    background-color: var(--green-100);
    border-radius: 25px;
    color: var(--white-200);
    /* padding: 70px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      padding: 70px;
    }

    h1 {
      text-transform: capitalize !important;
      font-size: 28px;
      color: var(--white-200);
      text-align: center;
    }

    p {
      font-size: 18px;
      color: var(--white-200);
      font-family: Poppins;

      text-align: center;
    }

    #input-description {
      text-align: left;
      margin-top: 60px;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    input {
      width: 426px;
      height: 78px;
      border-radius: 8px;
      margin: 24px 0 40px;
      color: var(--grey-500);
      font-size: 24px;
      padding-left: 10px;
    }

    .white:hover {
      background-color: var(--green-50);
      color: var(--white-200);
    }
  }
}

@media (max-width: 768px) {
  .myAccount-container {
    height: 100vh;

    .green-box {
      width: 88%;

      .content {
        padding: 35px;
      }

      h1 {
        font-size: 24px;
        margin-bottom: 20px;
        padding: 0px 100px;
      }

      p {
        font-size: 14px;
        text-align: left;
        padding-right: 100px;
      }

      #input-description {
        margin-top: 10px;
      }

      input {
        width: 100%;
        height: 48px;
        margin: 0px 0 30px;
        font-size: 14px;
      }

      .white {
        width: 232px;
        height: 56px;
        font-size: 18px;
      }
    }
  }
}
