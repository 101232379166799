.bdtreatment {
  width: 1134px;
  margin: 79px 32px 0px 101px;
}
.bannerMiddlePhoto img {
  height: 715px;
  width: 708px;
  object-fit: cover;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}

.aboveFoldContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 60px 0 60px 180px;
  gap: 20px;
}

.aboveFoldContentLeft {
  flex: 1;
  padding-right: 40px;
}

.aboveFoldContent {
  font-family: "Georgia", serif;
  color: var(--green-100);
  line-height: 1.6;
  margin-bottom: 40px;
}

.aboveFoldContent h1 {
  color: var(--green-100);
  font-family: "Roboto Serif";
  font-size: 32px;
  font-style: normal;
  line-height: 42px; /* 131.25% */
}
.aboveFoldContent h3 {
  color: var(--black-100);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0px;
  text-align: start;
  padding: 10px 0px;
}

.aboveFoldContent p {
  color: var(--black-100);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
a {
  color: blue;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

a:hover {
  color: var(--green-100);
  text-decoration: none;
}

.aboveFoldContent strong {
  font-weight: bold;
}

.aboveFoldContent em {
  font-style: italic;
}

.aboveFoldContent ul {
  margin-top: 1em;
  padding-left: 1.5em;
}

.aboveFoldContent li {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 0.5em;
}

.aboveFoldImage img {
  display: flex;
  width: 708px;
  height: 703px;
  border-radius: 6px 0px 0px 6px;
}

.green-fold {
  background-color: var(--green-50);
  width: 100vw;
  height: 200px;
}
.one {
  margin-top: -120px;
}

.belowFoldContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 180px;
  gap: 20px;
}

.belowFoldContent h2 {
  color: var(--green-100);
  font-family: "Roboto Serif";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 26px; */
  letter-spacing: 0px;
}

.belowFoldContent td {
  vertical-align: top;
  padding-right: 10px;
}

.belowFoldImage {
  display: flex;
  justify-content: center;
  padding: 60px 0;
}

@media (max-width: 768px) {
  .aboveFoldContainer {
    display: flex;
    flex-direction: column-reverse;
    padding: 5px 20px;
    height: 200vh;
  }

  .aboveFoldContent h1 {
    margin-top: -440px;
    margin-bottom: 500px;
    margin-left: 52px;
    position: relative;
    z-index: 1;
  }

  .aboveFoldImage img {
    width: 90%;
    height: 86%;
    top: 193px;
    z-index: 0;
    position: relative;
    left: 18%;
  }

  .belowFoldContent {
    padding: 0px 16px;
  }
}
