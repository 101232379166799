.bannerMiddle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 1500px;
  margin: 0 auto;
  height: auto;
}
.bannerMiddleContent {
  display: grid;
  grid-template-columns: 503px 550px;
  grid-template-rows: 0px auto;
  width: 100%;
  column-gap: 100px;

  p {
    margin: 0;
  }

  .descriptionStyle {
    display: inline;
  }

  .descriptionStyle p {
    display: inline;
    margin: 0;
    padding-left: 10px;
  }
}
.serviceIncludedWarning {
  max-width: 1134px;
  border: 1px solid black;
  margin: 0 0px 120px 101px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin: 28px;
  }
}

@media (max-width: 768px) {
  .serviceIncluded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: initial;
    padding: 0;
  }

  .serviceIncludedContent {
    max-width: 358px;
    margin: 60px 16px 120px 16px;
  }
  .serviceSteps {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 358px;
    padding: 0;

    .detail-item {
      width: 358px;
    }
  }
  .serviceTitle {
    margin-bottom: 32px;
    padding-left: 0px;
  }
  .serviceIncludedWarning {
    margin: 0px 16px 120px 16px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 16px 12px 16px 12px;

      .material-symbols-outlined {
        font-size: 88px !important;
      }
    }
  }
}
