.globalContainerService {
  display: flex;
  flex: column;
  justify-content: center;
  justify-content: space-between;
}
.globalNavbarWrapper {
  display: none;
}
.globalBanner {
  display: flex;
  width: 100%;
  height: 299px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding-left: 0px;
  background-color: var(--green-50);
}
.globalCenterLogo {
  h1 {
    display: inline-block;
    white-space: nowrap;
    font-size: 48px;
    font-weight: 300;
    line-height: 50px;
    font-stretch: ultra-condensed;
    color: white;
    font-family: "Roboto Serif", serif;
    font-style: normal;
    letter-spacing: 4px;
  }
}
.globalBannerBottom {
  background-color: var(--green-50);

  height: 130px;
  width: 100%;
}

.globalServiceIncluded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 101px;
}
.globalServiceIncludedContent {
  width: 100%;
  box-sizing: border-box;
  align-items: center;

  h2 {
    font-size: 32px;
    letter-spacing: 4px;
    padding-bottom: 35px;
    margin: 0;
  }

  h5 {
    margin: 0;
    padding-bottom: 10px;
    display: inline-block;
  }
}
.globalTreatment {
  margin: 79px 32px 120px 101px;
  width: 100%;
  max-width: 1134px;
}

.globalCustom-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.globalBulletsDescription {
  margin: 0px;
}
.globalCustom-list li {
  position: relative;
  padding-left: 20px;
}
.globalBannerMiddle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 1500px;
  margin: 0 auto;
  height: auto;
}
.globalBannerMiddleContent {
  display: grid;
  grid-template-columns: 503px 550px;
  grid-template-rows: 0px auto;
  width: 100%;
  column-gap: 100px;
  height: 250px;

  p {
    margin: 0;
  }

  .globalDescriptionStyle {
    display: inline;
    h5 {
      margin: 10px 0px;
    }
  }

  .globalDescriptionStyle p {
    display: inline;
    margin: 0;
    padding-left: 10px;
  }
}
.globalFirstBannerBottom {
  background-color: var(--green-50);

  height: 130px;
  width: 100%;
  margin-top: -85px;
}
.globalCustom-list li::before {
  content: "•";
  position: absolute;
  left: 0;
}

.globalMainBottomTitle {
  text-align: left;
  margin-bottom: 24px;
  letter-spacing: 1px;
}
.globalMainBottomTitleDescription {
  margin-bottom: 60px;
}
.globalMainBottomTreatment {
  padding: 0px;
  margin-top: 0px;
}
.globalWarningWrap {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.globalServiceIncludedWarning {
  max-width: 1500px;
  width: 1134px;
  border: 1px solid black;
  align-items: center;
  margin: 60px 32px 120px 0px;

  div {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 28px;
    margin-left: 26px;
  }

  ul {
    padding-left: 75px;
    padding-bottom: 25px;

    li {
      width: 900px;
    }
  }
}

.globalBenefits h5 {
  display: inline-block;
  font-family: "Raleway";
  font-weight: 550;
  font-size: 16px;
}

@media (max-width: 768px) {
  * {
    box-sizing: border-box;
  }
  .globalFirstBannerBottom {
    background-color: var(--green-50);

    height: 130px;
    width: 100%;
    margin-top: 0px;
    padding-top: 0px;
  }

  .globalServiceIncluded {
    width: 100%;
    margin: 0;
    padding-left: 0px;
  }

  .globalTreatment {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% - 32px);
    margin: 60px 16px 40px 16px;

    h5 {
      margin-bottom: 10px;
    }
  }

  .globalBanner {
    margin-top: 45px;
    height: 200px;
  }

  .globalCenterLogo {
    h1 {
      font-size: 30px;
      margin: 0;
    }
  }

  .globalBannerMiddleContent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: none;
    height: auto;
    gap: 0;
  }
  .globalWarningWrap {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .globalServiceIncludedWarning {
    max-width: 1500px;
    width: 1134px;
    border: 1px solid black;
    align-items: center;
    margin: 0px 16px 120px 16px;

    div {
      margin: 57px 0px 0px -40px;
    }

    ul {
      padding-left: 75px;
      padding-bottom: 25px;

      li {
        width: 900px;
      }
    }
  }
}
