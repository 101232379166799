@import "../style.css";

footer {
  width: 100vw;
  background-color: var(--green-100);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 27px;
    flex-shrink: 0;
  }

  a {
    color: var(--white-200);
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    margin: 0 10px;
    position: relative;
  }

  a,
  p {
    color: var(--white-200);
    text-align: center;
    text-decoration: none;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
  }

  a.active::after {
    content: "";
    display: block;
    width: 30px;
    height: 5px;
    background-size: contain;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  #line-footer {
    width: 85vw;
    margin-top: 30px;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    #logo {
      margin-top: 36px;
      width: 96px;
      height: 104px;
      flex-shrink: 0;
    }

    .footer-menu {
      display: flex;
      height: 80px;
      justify-content: center;
      align-items: center;
    }

    .footer-social {
      display: flex;
      justify-content: space-between;
      list-style: none;
      margin: 16px 0 40px;
      padding: 0;
      height: 27px;
      flex-shrink: 0;
      img {
        margin: auto 10px;
      }
    }

    .footer-contact {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .email-phone {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-right: 20px;

        img {
          width: 20px;
          height: 20px;
          margin: auto 10px;
        }
      }
    }
  }

  #copyright {
    margin-top: 12px;
    color: var(--white-200);
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 15px;

    a {
      color: var(--white-200);
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 12px 5px 15px 5px;
      text-decoration: none;
    }
  }
}

@media (max-width: 768px) {
  footer {
    width: 100vw;
    background-color: var(--green-100);
    height: auto;
    padding: 15px 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #line-footer {
    margin-top: 30px;
  }

  #logo {
    width: 80px;
    height: 88px;
  }

  .footer-menu {
    margin-top: 15px;
    white-space: nowrap;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60%;

    a {
      color: var(--white-200) !important;
      text-decoration: none !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      margin: 5px !important;
    }
    .footer-menu-list {
      flex-wrap: wrap !important;
      margin-bottom: 15px;

      li {
        margin-top: 10px;
      }
    }
  }

  .footer-social {
    a {
      margin: 0 15px !important;
    }
  }

  .footer-contact {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 20px;
  }
  .footer-contact {
    margin-top: 15px;

    a {
      margin: 15px !important;
    }

    p {
      margin: 15px !important;
    }
  }
}
