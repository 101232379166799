@import "../style.css";

body {
  z-index: 0;
}

h1 {
  font-family: "Roboto Serif";
  font-size: 40px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  display: flex;
  margin-bottom: 40px;
  text-transform: math-auto;
}

h3 {
  color: var(--green-100);
  font-family: "Roboto Serif" serif;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  text-transform: math-auto;
}

h4 {
  color: var(--green-100);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  /* font-weight: 500; */
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
}

h5 {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

p {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.container {
  flex-direction: column;
  width: 100vw;
}
.sub-title {
  margin-top: 24px;
}
.left-title {
  text-align: left;
  font-weight: bold;
  text-transform: math-auto !important;
}

.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 115px 0;
  width: 100vw;

  .images-container {
    display: flex;
    width: 50%;
  }

  .we-are-letih-beauty {
    display: flex;
    flex-direction: column;
    margin: calc(115px / 2);
    width: 40%;

    .mission-vision {
      margin-top: 63px;
    }
    h3 {
      letter-spacing: 4px;
      font-style: italic;
      font-size: 25px;
    }
    h4 {
      font-weight: 500;
    }
  }
}

.our-values {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100vw;
  padding: 100px 0;

  .our-values-content {
    margin-right: 140px;
    margin-left: 128px;
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: calc(115px / 2); */
  }
  h4 {
    font-weight: 500;
  }

  .images-container {
    display: flex;
    width: 50%;
  }
}

.behind-scenes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px auto 0;
  max-width: 1500px;

  .image-letih {
    display: flex;
    width: 576px;
    height: 516px;
    animation: fadeIn 2s forwards;
  }

  .behind-scenes-content {
    display: flex;
    flex-direction: column;
    margin: 370px 129px 120px;
  }
  h4 {
    font-weight: 500;
  }

  .btn-book {
    align-self: center;
    margin-top: 60px;
  }
}

.green-box {
  position: absolute;
  opacity: 0; /* Inicia invisível */
  animation: fadeIn 2s forwards;
}
.green-box-1 {
  top: 200px;
  left: 128px;
  animation-delay: 0.5s;
}

.green-box-2 {
  top: 487px;
  left: 336px;
  animation-delay: 0.5s;
}

.green-box-3 {
  margin-top: calc(-100px / 2);
  right: calc(570px / 2);
  animation-delay: 0.5s;
}

.green-box-4 {
  right: calc(140px / 2);
  margin-top: 232px;
  animation-delay: 0.5s;
}

.green-box-5 {
  align-self: center;
  position: relative;
  margin-top: 344px;
  margin-left: 156px;
  z-index: 0;
  animation-delay: 0.5s;
}

.women-image {
  position: absolute;
  z-index: 1; /* Imagem por cima dos quadros */
  width: 461px;
  height: 528px;
  opacity: 0; /* Começa invisível */
  animation: fadeInImage 2s forwards 1.5s;
  border-radius: 6px;
}

.women-top {
  left: 191px;
  top: 263px;
}

.women-middle {
  /* margin-left: calc(500px / 2); */
  /* margin-left: 15%; */
  right: 130px;
  margin-top: 20px;
}

.women-bottom-one {
  position: absolute;
  margin-left: -160px;
  z-index: 1;
  animation: fadeInImage 2s forwards 1.5s;
}

.women-bottom-two {
  position: absolute;
  margin-left: 267px;
  margin-top: 330px;
  z-index: 2;
  animation: fadeInImage 2s forwards 1.5s;
}

.bottom-images {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  max-width: 100%;
  gap: 55px;
  margin-bottom: 60px;
  overflow: hidden;
}
.bottom-images img {
  width: 100%;
  max-width: 570px;
  height: 480px;
  object-fit: cover;
}
.bottom-images img:nth-child(2) {
  margin-left: 0;
  margin-right: 0;
}
.bottomLineGreen {
  background-color: var(--green-100);
  height: 15px;
  width: 100%;
  margin-top: -60px;
}
.bottomLineWhite {
  background-color: white;
  margin-top: 120px;
}

/* Animação de fade in para os quadros verdes */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Animação de fade in para a imagem */
@keyframes fadeInImage {
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    margin: 50px 0;

    h1 {
      margin-bottom: 5px;
    }

    .images-container {
      .green-box-1 {
        top: 10%;
        left: 3%;
      }

      .green-box-2 {
        top: 22%;
        left: 12%;
      }

      .women-image {
        width: 70%;
        height: auto;
      }

      .women-top {
        left: 13%;
        top: 15%;
      }
    }

    .we-are-letih-beauty {
      width: 90%;
      margin-top: 110%;
      margin-left: 10px;
      align-items: center;

      .mission-vision {
        margin-top: 25px;
      }
    }
  }
  .our-values {
    flex-direction: column;
    .images-container {
      .women-image {
        width: 70%;
        height: auto;
      }

      .women-middle {
        top: 110%;
        right: 18%;
      }

      .green-box-3 {
        margin-top: -30%;
        right: 20%;
      }

      .green-box-4 {
        margin-top: -5%;
        right: 10%;
      }
    }

    .our-values-content {
      margin-left: 21px;
      margin-top: 85%;
      width: 88vw;
    }
  }

  .behind-scenes {
    margin: 0;

    .image-letih {
      width: 60%;
      height: auto;

      .women-bottom-one {
        margin-left: -15%;
      }

      .women-bottom-two {
        margin-top: 41%;
        margin-left: 16%;
      }

      .green-box-5 {
        margin-top: 10%;
        margin-left: 0px;
      }
    }
    .behind-scenes-content {
      margin: 18% 1% 10%;
      width: 88vw;
    }
  }

  .bottom-images,
  .bottomLineGreen,
  .bottomLineWhite {
    display: none;
  }
}
