@import "../style.css";

body {
  overflow-x: hidden;
}

h1 {
  font-family: "Roboto Serif", serif;
  color: var(--green-100);
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 74px;
  text-transform: uppercase !important;
}

h2 {
  color: var(--green-100);
  font-family: "Roboto Serif";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 125% */
  letter-spacing: 8px;
}

h3 {
  color: var(--green-100);
  text-align: center;
  font-family: "SVN-Aire Pro";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 6px;
  text-transform: uppercase;
}

h4 {
  color: var(--green-100);
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 4.8px;
}

h5 {
  color: var(--Black-100, #000);
  font-family: "Roboto Serif";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  margin: 0;
}
.special-style {
  color: var(--Black-100);
  font-family: "Roboto Serif";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 2px;
}
.special-title-green {
  color: var(--green-100);
  font-family: "Roboto Serif";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  /* 125% */
  letter-spacing: 2px;
}
.special-title-white {
  color: var(--white-200);
  font-family: "Roboto Serif";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  /* 125% */
  letter-spacing: 2px;
}

h6 {
  color: var(--Black-100, #000);
  font-family: "Roboto Serif";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

p {
  color: var(--Black-100, #000);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  margin-bottom: 32px;
}

.banner-desktop {
  height: 840px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-color: var(--white-200);

  .content {
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    margin: 257px auto auto 128px;
    flex-direction: column;
  }

  img {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    height: 841px;
    object-fit: cover;
    clip-path: polygon(0% 0, 430% 0, 50% 100%, 0 85%);
  }

  h1 {
    width: 533px;
    color: var(--green-100);
    position: static;
    font-style: normal;
    top: 257px;
    left: 128px;
    text-align: left;
    margin: 0;
  }

  h4 {
    text-align: left;
    color: var(--green-100);
    top: 452px;
    left: 128px;
    margin: 20px 0;
  }
  h5 {
    color: #154734;
    font-family: Raleway;
    margin: 0;
    padding-bottom: 10px;
    display: inline-block;
    text-align: left;
  }
  .bannerTitle {
    font-family: "Roboto Serif";
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 74px; /* 115.625% */
    text-transform: uppercase;
    padding-bottom: 30px;
  }
  .bannerDescrip {
    padding-bottom: 30px;
  }
}

.banner-mobile {
  display: none;
}

.about-us,
.our-services {
  width: 99vw;
  /* min-height: 100vh; */
}
.about-us {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  background-color: var(--white-200);
  padding-top: 80px;
  margin-bottom: 20px;
}

.about-us-content {
  width: 99vw;
  margin: 80px 300px 0px;
  position: relative;
}

.about-us-img-custom {
  width: 625px;
  height: 420px;
  flex-shrink: 0;
  border-radius: 15px;
}

.about-us-img-left {
  z-index: 2;
}

.about-us-img-right {
  z-index: 1;
  margin-left: -80px; /* Ajuste para a sobreposição */
}

.about-us-top {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 1;
}

.about-us-top-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 50px;
  /* Ajuste conforme necessário */
  transform: translateY(-50px);
  /* Move o texto para cima sem usar margem negativa */
  position: relative;
  width: 519px;
}

.about-us-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 0;
  z-index: 0;
  position: relative;
  transform: translateY(-50px);
}

/* .about-us-bottom img {
  /* transform: translateY(-50px); */
/* Ajuste conforme necessário */

.about-us-bottom-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 50px;
  position: relative;
  width: 519px;
}

.our-services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;

  .service-image {
    width: 500px;
    height: 332px;
    flex-shrink: 0;
  }

  h2 {
    margin-bottom: 80px;
  }
  .service-content {
    margin: auto 80px;
  }

  .green-services,
  .white-services {
    width: 100%; /* Garante que o background cubra toda a tela */
    padding: 60px 170px; /* Ajuste o padding conforme necessário */
    display: flex;
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    align-items: center; /* Alinha verticalmente */

    h5 {
      font-family: "Roboto Serif";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 32px;
    }
  }

  .green-services {
    background-color: var(--green-50);
    flex-direction: row; /* Imagem à direita */

    h5,
    p {
      color: var(--white-200);
    }

    .green-right {
      max-width: 600px;
      display: flex;
      flex-direction: column;
      padding: 0; /* Remove padding interno se necessário */
    }
  }

  .white-services {
    background-color: var(--white-200);
    flex-direction: row-reverse; /* Imagem à esquerda */

    h5,
    p {
      color: var(--green-50);
    }

    .white-left {
      max-width: 600px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.advantages {
  width: 98vw;
  padding: 80px 0;
  display: flex;
  flex-direction: column; /* Mantém o título no topo centralizado */
  align-items: center; /* Centraliza o conteúdo horizontalmente */
}

.advantages h2 {
  text-align: center;
}

.advantages .advantages-icons {
  padding: 80px 0;
  display: flex;
  flex-direction: row; /* Alinha os ícones horizontalmente */
  justify-content: center; /* Centraliza os ícones horizontalmente dentro do container */
  align-items: flex-start; /* Alinha os ícones e textos no topo do container */
  gap: 80px; /* Espaçamento entre os ícones */
  flex-wrap: wrap; /* Permite que os ícones quebrem linha se necessário */
  max-width: 1200px; /* Limita a largura máxima do contêiner */
}

.advantages .icon-content {
  display: flex;
  flex-direction: column; /* Empilha a imagem e o texto verticalmente */
  align-items: center; /* Centraliza a imagem e o texto horizontalmente */
  text-align: center; /* Centraliza o texto dentro do bloco */
  max-width: 220px; /* Limita a largura do bloco de ícones */
}

.advantages .icon-content img {
  margin-bottom: 24px; /* Espaçamento aumentado entre a imagem e o texto */
  max-width: 100px; /* Limita o tamanho da imagem */
}

.advantages .icon-content h6 {
  margin: 8px 0; /* Espaçamento adicional acima e abaixo do título */
}

.advantages .icon-content p {
  color: var(--Black-100, #000);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0; /* Remove margens padrão para o parágrafo */
}

.contact {
  width: 100vw;
  background-color: var(--green-50);
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 60px 0 43px 0;
    color: var(--white-200);
  }

  p {
    color: var(--white-200);
    font-size: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 30px;

    .name-email {
      /* min-width: 100%; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      margin: 0 0 36px 0;
      min-width: 50%;
    }

    .form-group-phone input {
      display: flex;
      flex-direction: column;
      /* margin: 20px 20px 20px 0; */
      margin: 0 0 36px 0;
      min-width: 48%;
      /* margin-right: 20px; */
    }

    label {
      color: var(--White-100, #fff);
      font-family: Raleway;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 4.8px;
      margin-bottom: 5px;
    }

    input,
    textarea {
      border: 1px solid var(--white-200);
      background-color: var(--green-50);
      padding: 10px;
      margin-right: 20px;
      font-size: 1em;
      border: 1px solid #ccc;
      color: var(--white-200);
      border: 1px solid var(--white-200);
    }

    textarea {
      height: 220px;
    }

    input::placeholder,
    textarea::placeholder {
      color: var(--white-200);
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.8px;
    }

    input:focus,
    textarea:focus {
      outline: none;
      border-color: var(--white-200);
      font-family: Raleway;
    }

    .btn-send {
      display: flex;
      justify-content: center;
      margin: 30px 0 60px 0;
    }
  }
}

.blank {
  height: 80px;
  background-color: var(--white-200);
}

@media (max-width: 768px) {
  /* * {
    border: 1px solid red;
  } */
  body {
    margin: 0;
    /* Remove margens padrão do body */
  }

  .banner-desktop {
    display: none;
  }

  .banner-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .banner-mobile img {
    height: 500px;
    width: 100%;
    margin-top: 52px;
    object-fit: cover;
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 50% 100%, 0% 90%);
    object-position: 80% center;
    z-index: 0;
  }

  .banner-mobile h1 {
    width: 350px;
    margin: 20px 0px;
    text-align: center;
    font-size: 30px;
    line-height: 35px;
  }
  .banner-mobile h5 {
    margin: 10px 10px 20px 10px;
    word-wrap: break-word;
    font-size: 20px;
  }

  .about-us {
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--white-200);
    padding-top: 50px;
    margin-bottom: 20px;
    box-sizing: border-box;

    h2 {
      margin-bottom: 50px;
    }
  }

  .about-us-content {
    margin: 40px 0px 0px;
    position: relative;
    overflow: visible;
  }

  .about-us-img-custom {
    width: 300px;
    height: 250px;
    flex-shrink: 0;
    border-radius: 15px;
  }

  .about-us-img-left {
    z-index: 2;
    /* margin-left: -80px; */
  }

  .about-us-img-right {
    position: relative;
    z-index: 1;
    margin-right: -290px;
  }

  .about-us-top-right {
    display: none;
  }

  .about-us-bottom {
    flex-direction: column;
    margin-right: 16px;
    margin-left: 16px;
  }

  .about-us-top img {
    transform: translateY(-80px) translateX(40px);
    width: 69%;
    margin-right: 34%;
  }

  .about-us-bottom img {
    transform: translateY(-80px) translateX(40px);
    width: 69%;
    margin-top: 17px;
  }

  .about-us-bottom-left {
    width: 90%;
    top: -50px;

    p {
      margin: 0;
      padding: 20px 0px 20px 0px;
    }
  }
  .our-services {
    width: 100vw;

    h2 {
      margin-bottom: 30px;
    }

    .service-content {
      margin: 25px 50px;
    }

    .green-services,
    .white-services {
      overflow-x: hidden;
      width: 100vw;
      padding: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      img {
        width: 90vw;
        height: auto;
      }

      p {
        margin: 0;
        /* padding: 20px 0px 20px 0px; */
      }
      .custom-button {
        margin-top: 10px;
      }
    }

    .green-services {
      flex-direction: column;
      margin: 30px 0px 10px 0px;
    }

    .white-services {
      background-color: var(--white-200);
      flex-direction: column;

      .white-left {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 30px 0px 10px 0px;
        text-align: right;

        p {
          color: var(--green-50);
          text-align: right;
        }
      }
    }
  }
  .advantages {
    overflow-x: hidden;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }

  .advantages h2 {
    text-align: center;
  }

  .advantages .advantages-icons {
    padding: 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    flex-wrap: wrap;
    max-width: 1200px;
  }

  .advantages .icon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: calc(38% - 15px);
    margin: 20px 0;
  }

  .advantages .icon-content img {
    margin-bottom: 24px;
    max-width: 100px;
  }

  .advantages .icon-content h6 {
    margin: 8px 0;
  }

  .advantages .icon-content p {
    color: var(--Black-100, #000);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
  }
  .our-gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin: 40px 0;
    }

    .blank {
      height: 80px;
      background-color: var(--white-200);
    }
  }

  .contact {
    overflow-x: hidden;
    background-color: var(--green-50);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    h2 {
      margin: 40px 0 20px 0;
      color: var(--white-200);
    }

    p {
      color: var(--white-200);
      font-size: 18px;
      margin: 0 auto;
      text-align: center;
      max-width: 360px;
    }

    form {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      padding: 20px 20px 20px 30px;
      box-sizing: border-box;

      .name-email {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 24px;
        margin-bottom: 24px;
        margin-left: -10px;
      }
      .form-group {
        margin-bottom: 20px;
        width: calc(100% - 20px);
      }

      .form-group input,
      .form-group-phone input,
      textarea {
        padding: 10px;
        font-size: 1em;
      }

      label {
        color: var(--White-100, #fff);
        font-family: Raleway;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 4.8px;
        margin-bottom: 5px;
      }

      input,
      textarea {
        border: 1px solid var(--white-200);
        background-color: var(--green-50);
        padding: 10px;
        margin-right: 0;
        font-size: 1em;
        border: 1px solid #ccc;
        color: var(--white-200);
        border: 1px solid var(--white-200);
        width: calc(100% - 20px);
      }

      textarea {
        height: 220px;
      }

      input::placeholder,
      textarea::placeholder {
        color: var(--white-200);
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.8px;
      }

      input:focus,
      textarea:focus {
        outline: none;
        border-color: var(--white-200);
        font-family: Raleway;
      }

      .btn-send {
        display: flex;
        justify-content: center;
        margin: 30px 0 60px 0;
      }
    }
  }
}
